
import { Component, Vue } from 'vue-property-decorator'
import DepositInvoice from '@/modules/components/DepositInvoice.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import Modal from '@/components/Modal.vue'
import { namespace } from 'vuex-class'
const UserStore = namespace('UserStore')
import { UserModel } from '@/core/models/UserModel'

@Component({
  name: 'TariffDetails',
  components: {
    DepositInvoice,
    BaseButton,
    Modal,
  },
})
export default class extends Vue {
  @UserStore.Getter
  public currentUser!: UserModel

  get amount(): number {
    if (
      this.currentUser.billing_plan.next &&
      this.currentUser.billing_plan.next.sum &&
      Number(this.currentUser.account) < this.currentUser.billing_plan.next.sum
    ) {
      return (
        this.currentUser.billing_plan.next.sum -
        Number(this.currentUser.account)
      )
    }
    return 0
  }

  get monthDiff(): number | null {
    if (
      this.currentUser.billing_plan.current &&
      this.currentUser.billing_plan.current.active_from &&
      this.currentUser.billing_plan.current.active_to
    ) {
      const from = new Date(this.currentUser.billing_plan.current.active_from)
      const to = new Date(this.currentUser.billing_plan.current.active_to)
      return (
        to.getMonth() -
        from.getMonth() +
        12 * (to.getFullYear() - from.getFullYear())
      )
    }
    return null
  }

  loading = false
  showErrorModal = false
  errorMessage = ''
  showDepositInvoice = false

  /* pay(): void {
    this.loading = true
    try {
      const response = await TariffService.pay()
      if (response?.payment_link) {
        this.redirect(response.payment_link)
      }
    } catch (error) {
      this.errorMessage = ''
      if (TariffService.isServiceError(error)) {
        const errors = error.response?.data?.errors || []
        this.errorMessage = errors[0] || error.message
      }
      this.errorMessage = this.errorMessage || 'Неизвестная ошибка'
      this.showErrorModal = true
    }
    this.loading = false
  } */

  redirect(url: string): void {
    if (typeof url === 'string' && url.startsWith('http')) {
      window.location.href = url
    } else {
      this.$router.push(url)
    }
  }
}
